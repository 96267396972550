import React from 'react';
import './App.css';

const App = () => (
  <div className="app">
    <h1 className="title text-color">bioma</h1>
    <div className="text-color">
      <p className="copyright">© bioma 2021</p>
      <p className="photo">
        Photo by{' '}
        <a
          href="https://unsplash.com/@vinayaknarayanofficial"
          target="_blank"
          rel="noopener noreferrer"
        >
          Vinayak Narayan
        </a>
      </p>
    </div>
  </div>
);

export default App;
